import React from "react"
import styled from "styled-components"
import ContactForm from "../components/contact"
import Layout from "../components/layout"
import { HeaderStyle } from "../components/styles/main"
const ContactPageWrapper = styled.div`
  padding: 1rem 2rem;
  p {
    width: 50%;
    margin-top: 3rem;
  }
`
const contact = ({ location }) => (
  <Layout>
    <ContactPageWrapper>
      <HeaderStyle>Let's Talk</HeaderStyle>
      <p>
        We would love to speak with you about ways that we can help to identify
        your right major and future career path. Please complete this form or
        call (631) 432-9192.
      </p>
      {location.state && location.state.title ? (
        <ContactForm subject={location.state.title || null} />
      ) : (
        <ContactForm subject={null} />
      )}
    </ContactPageWrapper>
  </Layout>
)

export default contact
