import styled from "styled-components"

export const HeaderStyle = styled.h1`
  width: 80%;
  border-bottom: 1px solid #6f8896;
  color: #ad1212;
  padding: 0rem 0px 0.15rem 1rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
`

export const ByLine = styled.p`
  margin: 2.2rem 0.5rem;
  color: #656769;
`
