import React from "react"
import styled from "styled-components"
import Form from "react-bootstrap/Form"

const ContactFormWrapper = styled.div`
  width: 45%;
  label {
    margin-top: 1rem;
  }

  input[type="submit"] {
    width: 50%;
    margin: 3rem 0px;
    background-color: rgb(64, 99, 119);
    color: white;
    :hover {
      opacity: 0.8;
    }
  }
`
const contact = ({ subject }) => {
  return (
    <ContactFormWrapper>
      <Form action="https://formspree.io/sdenehan@gmail.com" method="POST">
        <input
          type="hidden"
          name="_subject"
          value={subject || "New HRPC Inquiry."}
        />
        <input type="hidden" name="_next" value="http://localhost:8000/" />
        <Form.Row>
          <Form.Label htmlFor="name">Your Name</Form.Label>
          <Form.Control type="text" name="name" />
        </Form.Row>
        <Form.Row>
          <Form.Label htmlFor="_replyto">Your E-Mail</Form.Label>
          <Form.Control type="email" name="_replyto" />
        </Form.Row>
        <Form.Row>
          <Form.Label htmlFor="phone">Phone Number</Form.Label>
          <Form.Control type="text" name="phone" />
        </Form.Row>
        <Form.Row>
          <Form.Label htmlFor="message">Message</Form.Label>
          <Form.Control as="textarea" rows="8" name="Message" />
        </Form.Row>
        <Form.Control type="submit" value="Send" />
      </Form>
    </ContactFormWrapper>
  )
}

export default contact
